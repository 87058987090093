* {
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  /* display: none; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat",
    "Montserrat-Black", "NotoSansCJKkr-Light", "NotoSansCJKkr-Black",
    "NotoSansCJKkr-Bold", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:lang(en) {
  font-family: "Montserrat", sans-serif;
}
:lang(ko) {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -1.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background: #642ad6 !important;
}
pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Montserrat";
}
